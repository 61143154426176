<template>
  <div class="container">
    <div class="row" v-if="!firstTime">
      <div class="col-sm-12 pm-0">
        <button type="button" class="btn btn-primary nr-shadow btn-sm mr-1" @click="setList" title="تحديث">
          <i class="fas fa-sync"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered bg-light text-center table-striped">
            <thead>
            <tr class="bg-info text-light">
              <th>النشاط</th>
              <th>التاريخ</th>
              <th>الوقت</th>
              <th v-if="edit">تعديل</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="emptyList">
              <td colspan="4" class="font-weight-bolder">
                <label class="link" v-if="firstTime" @click="setNotFirstTime">{{ showLogText }}</label>
                <label v-else>لا يوجد سجلات</label>
              </td>
            </tr>
            <tr v-else v-for="log in list" :key="log.id" :id="`log-row-${log.id}`">
              <td>{{ log.description }}</td>
              <td>{{ log.date }}</td>
              <td>{{ log.time }}</td>
              <td v-if="edit" class="space-x-2">
                <RouterLink
                  target="_blank"
                  v-text="'عرض'"
                  v-if="isUser && log.target_id > 0"
                  class="btn btn-sm btn-info rounded-0"
                  :to="{name: 'auctions.edit', params: {auction: log.target_id}}"
                />
                <modal-btn btn-class="btn btn-sm btn-danger" :target="`#delete-log${log.id}`" title="حذف سجل">
                  حذف
                </modal-btn>
              </td>
              <teleport to="#app" v-if="edit">
                <items-destroyer
                  title="حذف سجل"
                  :url="`logs/${log.id}`"
                  :row-id="`log-row-${log.id}`"
                  :target-id="`delete-log${log.id}`">
                  هل أنت متأكد من حذف هذا النشاط ؟
                  <p>
                    <small class="link" v-if="isAuction">
                      يرجى الإنتباه, في حال حذف اخر مزاودة سوف يتغير سعر المزاد الحالي
                    </small>
                  </p>
                </items-destroyer>
              </teleport>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0 bg-e0">
        <ThePaginator v-if="!emptyList" :total="total" url="/logs" :last-page="lastPage" :links="links" @link-changed="setLink" :stick="10" />
      </div>
    </div>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";

export default {
  components: {ItemsDestroyer, ModalBtn, ThePaginator},
  mixins: [pagination],
  props: {
    id: {required: true},
    ownerType: {required: true},
    edit: {default: true},
    showLogText: {default: 'عرض السجل'},
    allowFirstTime: {default: true}
  },
  data() {
    return {
      firstTime: true,
      showLog: false,
      formData: {
        id: '',
        type: '',
      }
    };
  },
  watch: {
    async showLog(show) {
      if (show)
        await this.setList();
    }
  },
  computed: {
    defaultUrl() {
      return '/logs';
    },
    isAuction() {
      return this.ownerType === 'auctions';
    },
    isUser() {
      return this.ownerType === 'user';
    },
  },
  methods: {
    setShowLog() {
      this.showLog = !this.showLog;
    },
    async setNotFirstTime() {
      this.firstTime = false;
      await this.setList();
    }
  },
  created() {
    if (!this.id || !this.ownerType) {
      return false;
    }

    this.formData.id = this.id;
    this.formData.type = this.ownerType;

    if (this.allowFirstTime === false)
      this.setNotFirstTime()
  }
}
</script>
