<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 pm-0">
        <base-card :with-header="false" body="pm-0 bg-transparent">
          <template #body>
            <LogsHistory
              :id="auctionId"
              owner-type="auction"
              :edit="false"
              :allow-first-time="false"
            />
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import LogsHistory from "@/components/Common/LogsHistory";

export default {
  components: {LogsHistory, BaseCard},
  computed: {
    auctionId() {
      return this.$route.params.auction;
    },
    shouldReload() {
      return this.$store.getters.shouldReload
    },
  }
}
</script>